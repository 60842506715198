import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import { json, useNavigate } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <header className="Olavs CloudResume">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Made this thing with <code>React.js</code>, for shits and giggles.
        </p>

        <div>
          <h1>
            I made buttons
          </h1>
          <MyButton />
          <MyButton />
        </div>

        <div>
          <h3>
            Visitor counter so i can see how unpopular i am: <VisitorCounter />
          </h3>
        </div>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <a 
      className='CV-link'
      href={`${window.location.href}CV.pdf`}
      target='_blank'
      rel='noopener noreferrer'
      >
        Check out CV here!
      </a>
    </div>
  );
}

function MyButton(){
  const [count, setCount] = useState(0);
  function handleClick(){
    setCount(count+1);
  }
  return (
    <button onClick={handleClick}>
      Click me {count}
    </button>
  );
}

const VisitorCounter = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href; // Full URL
    const baseUrl = currentUrl.replace(/^https?:\/\//, '')
    // Construct the relative URL: 'visitor-counter.<currentUrl>'
    const relativeUrl = `https://visitorcounter.${baseUrl}`;
    
    // Fetch the data from the API
    fetch(relativeUrl,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: 'value1' }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error.toString());
      });
  }, []);

  return (
    <div>
      {error && <p>Error: {error}</p>}
      {data ? <pre>{JSON.stringify(data.counter, null, 2)}</pre> : <p>Loading... </p>}
    </div>
  );
};

export default App;
